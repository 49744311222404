import React from 'react'

const MvpReasons = () => (
  <section className="section bg-faded">
    <div className="container">
      <h2 className="text-center mt-2">
        Rust je start-up uit met full-stack ingenieurs
      </h2>
      <div className="card-group card-group-transparent my-2 mvp-reasons">
        <div className="card">
          <div className="card-body">
          <span className="text-nowrap">Snelle prototypering -</span>
            <br />
            <em className="text-muted">
              <abbr title="Minimum Viable Product">MVP</abbr> wordt geleverd binnen 12-16 weken
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Volledige teamondersteuning:
            <br />
            <em className="text-muted">
            Analyse, PM, ontwikkelaars, ontwerpers, testers
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          <span className="text-nowrap">Gebruiksvriendelijke</span> en mooie UI/UX < br/>
            <em className="text-muted">
            ontworpen door&nbsp;
              <span className="text-nowrap">ons eigen</span> ontwerpbureau
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
           Soepele programmering bestuurd
            <br />
            <em className="text-muted">volgens de principes van SCRUM</em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            Langdurige ondersteuning - 
            <br />
            <em className="text-muted">
              blijf je product ontwikkelen na de MVP-periode
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Outsourcingsvriendelijke afstandsbesturing - 
            <br />
            <em className="text-muted">
              drempelvrije programmering 
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default MvpReasons
