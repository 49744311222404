import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faApple,
} from '@fortawesome/fontawesome-free-brands'
import Link from '../../common/Link'

const ServiceMobileOffer = () => (
  <section className="service-mobile">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Oplossingen van mobiele toepassingen</span>
            <br />
             voor ondernemingen
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group text-center">
            <div className="card">
              <div className="card-body">
                <h3>MOBIELE ONTWIKKELING UITBESTEDING</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Raadpleeg onze
                <Link to="/services/poland-software-outsourcing/">
                outsourcingsgids
                  </Link> om te weten te komen welk model het best bij je past.{' '}
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>VERANDER JE INTERNETPAGINA IN EEN MOBIELE TOEPASSING</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Neem de volgende stap en verander je huidig platform in een winstgevende toepassing.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>KANT-EN-KLARE MOBIELE TOEPASSING</h3>
                <p className="text-sm text-muted text-italic mt-2">
                We staan klaar voor het maken van elk soort toepassing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">
              Mobiele toepassingen - </span>
            <strong>ontwerpoplossingen</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8 pt-2">
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faApple} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">HET ONTWERPEN VAN IOS TOEPASSINGEN</strong>
              <p className="text-muted">
              Afgestemd op de behoeften van iPhones, iPads en Apple watches en
               gericht op de klant van de App Store, zodat ze zelfs de meest 
               kieskeurige gebruikers bevallen.

              </p>
            </div>
          </div>
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faAndroid} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">HET ONTWERPEN VAN ANDROID TOEPASSINGEN</strong>
              <p className="text-muted">
              Het onderhouden van een doelmatige en operationele interface van de gebruiker 
              in het Android-ecosysteem is gericht op het vervullen van de behoeften 
              van de grootste groep smartphonegebruikers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceMobileOffer
