import React from 'react'

const ServiceMobileTags = () => (
  <section className="service-mobile bg-faded py-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-1">
            <span className="text-muted seo-regular">Aanmaak van mobiele toepassingen - </span>
            <br />
            <strong>kosten</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="row">
            <div className="col-sm-4">
              <strong>Prijsopgave van offshoring</strong>
              <br />
              Hoge kwaliteit voor een lage prijs
            </div>
            <div className="col-sm-4">
              <strong>Time & Materials</strong>
              <br />
              betaling in handige termijnen
            </div>
            <div className="col-sm-4">
              <strong>GRATIS</strong>
              <br />
              Een gratis evaluatie van je idee
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Het maken van</span>
            <br />
            mobiele applicaties
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <ul className="list-unstyled list-inline list-badges lead">
            <li>
              <span className="badge badge-light">Mobiele strategy</span>
            </li>
            <li>
              <span className="badge badge-light">Bedrijfsanalyse</span>
            </li>
            <li>
              <span className="badge badge-light">
                Het beste project voor een mobiele toepassing
              </span>
            </li>
            <li>
              <strong className="badge badge-light">
                Prototypering van de toepassing
              </strong>
            </li>
            <li>
              <span className="badge badge-light">
                Integratie met cloud en API
              </span>
            </li>
            <li>
              <strong className="badge badge-light">
                Ontwikkeling van mobiele apps
              </strong>
            </li>
            <li>
              <span className="badge badge-light">Testen van QA</span>
            </li>
            <li>
              <span className="badge badge-light">Publicatie in App Store</span>
            </li>
            <li>
              <span className="badge badge-light">Onderhoud en ondersteuning</span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4" />
        <div className="col-sm-12 col-md-8" />
      </div>
    </div>
  </section>
)

export default ServiceMobileTags
